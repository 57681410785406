import React from "react";

export default function Photography() {
  return (
    <div>
      <div className="bg-dark p-2">
        <h1 className="text-secondary text-5xl text-center">Photography</h1>
        <br />
        <div className="">
        <div id="curator-feed-default-feed-layout"><a href="https://curator.io" rel="noreferrer" target="_blank" className="crt-logo crt-tag">Powered by Curator.io</a></div>
        </div>
      </div>
    </div>
  );
}
