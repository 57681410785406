import React from "react";

function App() {

  return (
    <div className="p-2">

      <div className="flex flex-wrap">
        <div className="p-2 lg:w-2/5 w-full mx-auto">
          <div className="shadow-none hover:shadow-xl h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
            <img
              className="lg:h-56 md:h-64 sm:h-72 h-64 w-full object-cover hover:object-contain object-center"
              src="/img/about2.jpg"
              alt="Me"
            />
            <div className="p-6">
              <h2 className="tracking-widest text-xs title-font font-medium text-light2 mb-1">
                PAGE
              </h2>
              <h1 className="title-font text-lg font-medium text-lighter mb-3">
                About
              </h1>
              <p className="leading-relaxed mb-3 text-light2">See My About Me</p>
              <div className="flex items-center flex-wrap">
                <a
                  href="/about"
                  className="text-accent inline-flex items-center md:mb-2 lg:mb-0"
                >
                  See More
                  <svg
                    className="w-4 h-4 ml-2"
                    viewBox="0 0 24 24"
                    stroke="#00fdd2"
                    stroke-width="2"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M5 12h14"></path>
                    <path d="M12 5l7 7-7 7"></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="p-2 lg:w-2/5 w-full mx-auto">
          <div className="shadow-none hover:shadow-xl h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
            <img
              className="lg:h-56 md:h-64 sm:h-72 h-64 w-full object-cover hover:object-contain object-top"
              src="/img/resume.png"
              alt="The public website for One Iota Performance"
            />
            <div className="p-6">
              <h2 className="tracking-widest text-xs title-font font-medium text-light2 mb-1">
                PAGE
              </h2>
              <h1 className="title-font text-lg font-medium text-lighter mb-3">
                Resume
              </h1>
              <p className="leading-relaxed mb-3 text-light2">See My Resume</p>
              <div className="flex items-center flex-wrap">
                <a
                  href="/resume"
                  className="text-accent inline-flex items-center md:mb-2 lg:mb-0"
                >
                  See More
                  <svg
                    className="w-4 h-4 ml-2"
                    viewBox="0 0 24 24"
                    stroke="#00fdd2"
                    stroke-width="2"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M5 12h14"></path>
                    <path d="M12 5l7 7-7 7"></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="p-2 lg:w-2/5 w-full mx-auto">
          <div className="shadow-none hover:shadow-xl h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
            <img
              className="lg:h-56 md:h-64 sm:h-72 h-64 w-full object-cover hover:object-contain object-top"
              src="/img/pipboy.jpg"
              alt="One of my favourite projects"
            />
            <div className="p-6">
              <h2 className="tracking-widest text-xs title-font font-medium text-light2 mb-1">
                PAGE
              </h2>
              <h1 className="title-font text-lg font-medium text-lighter mb-3">
                Projects
              </h1>
              <p className="leading-relaxed mb-3 text-light2">See My Projects</p>
              <div className="flex items-center flex-wrap">
                <a
                  href="/projects"
                  className="text-accent inline-flex items-center md:mb-2 lg:mb-0"
                >
                  See More
                  <svg
                    className="w-4 h-4 ml-2"
                    viewBox="0 0 24 24"
                    stroke="#00fdd2"
                    stroke-width="2"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M5 12h14"></path>
                    <path d="M12 5l7 7-7 7"></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="p-2 lg:w-2/5 w-full mx-auto">
          <div className="shadow-none hover:shadow-xl h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
            <img
              className="lg:h-56 md:h-64 sm:h-72 h-64 w-full object-cover hover:object-contain object-top"
              src="/img/instimage.jpg"
              alt="SFU"
            />
            <div className="p-6">
              <h2 className="tracking-widest text-xs title-font font-medium text-light2 mb-1">
                PAGE
              </h2>
              <h1 className="title-font text-lg font-medium text-lighter mb-3">
                Photography
              </h1>
              <p className="leading-relaxed mb-3 text-light2">See My Photography</p>
              <div className="flex items-center flex-wrap">
                <a
                  href="/photography"
                  className="text-accent inline-flex items-center md:mb-2 lg:mb-0"
                >
                  See More
                  <svg
                    className="w-4 h-4 ml-2"
                    viewBox="0 0 24 24"
                    stroke="#00fdd2"
                    stroke-width="2"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M5 12h14"></path>
                    <path d="M12 5l7 7-7 7"></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>
  );
}

export default App;
