import React from "react";
import { listOfWorkExperience } from "./listOfWorkExperience";
import { WorkExperience } from "../types/DataTypes";
import { EducationData } from "../types/DataTypes";
import { listOfEducation } from "./listOfEducation";
import { listOfProjects } from "./listOfProjects";
import { Project } from "../types/DataTypes";

function Resume() {
  let experience: WorkExperience[] = [];
  for (let i = listOfWorkExperience.length; i > 0; i--) {
    experience.push(listOfWorkExperience[i - 1]);
  }

  let education: EducationData[] = [];
  for (let i = listOfEducation.length; i > 0; i--) {
    education.push(listOfEducation[i - 1]);
  }

  let projects: Project[] = [];
  for (let i = listOfProjects.length; i > listOfProjects.length - 3; i--) {
    projects.push(listOfProjects[i - 1]);
  }

  let languages: string[] = [
    "C/C++",
    "Python",
    "Java",
    "JavaScript",
    "TypeScript",
    "HTML/CSS",
    "SQL",
    "EJS",
    "Kotlin",
  ];

  let libraries: string[] = [
    "Angular",
    "React",
    "Node.js",
    "Spring Boot",
    "Express",
    "Bootstrap",
    "TailwindCSS",
    "jQuery",
    "D3.js",
    "Compose",
  ];

  let others: string[] = [
    "Git",
    "VSCode",
    "Google Cloud",
    "PostgreSQL",
    "MySQL",
    "Heroku",
    "OAuth2",
    "REST APIs",
    "Linux",
    "Windows",
    "Android",
    "Apache",
    "IIS",
    "Firestore",
    "Firebase",
    "Firebase Authentication",
  ];

  return (
    <div className="">
      <div id="resume" className="p-2">
        <h1 className="text-secondary text-5xl text-center">Resume</h1>
        <br />
        <br />
        <div className="mx-2 p-2 rounded-lg shadow-none hover:shadow-xl bg-dark">
          <h1 className="p-1 text-2xl text-light">Skills</h1>
          <div className="p-1" id="skillsMenu">
            <br />
            <h2 className="text-lg text-accent"><i className="fa-solid fa-code"></i> Languages:</h2>
            <div className="w-full flex flex-wrap">
              {languages.map((language) => {
                return (
                  <span className="inline-flex items-center justify-center px-3 py-1 mr-2 my-2 text-sm font-medium leading-5 text-[#F8F4E3] bg-secondary/10 rounded-full">
                    {language}
                  </span>
                );
              })}
            </div>
            <br />
            <h2 className="text-lg text-accent"> <i className="fa-solid fa-book"></i> Frameworks/Libraries:</h2>
            <div className="w-full flex flex-wrap">
              {libraries.map((library) => {
                return (
                  <span className="inline-flex items-center justify-center px-3 py-1 mr-2 my-2 text-sm font-medium leading-5 text-[#F8F4E3] bg-secondary/10 rounded-full">
                    {library}
                  </span>
                );
              })}
            </div>
            <br />
            <h2 className="text-lg text-accent"><i className="fa-solid fa-list"></i> Other:</h2>
            <div className="w-full flex flex-wrap">
              {others.map((other) => {
                return (
                  <span className="inline-flex items-center justify-center px-3 py-1 mr-2 my-2 text-sm font-medium leading-5 text-[#F8F4E3] bg-secondary/10 rounded-full">
                    {other}
                  </span>
                );
              })}
            </div>
          </div>
        </div>

        <br />
        <hr className="mx-2 text-accent border-gray-500 border-rounded border" />
        <br />

        <div
          id="experience"
          className="mx-2 p-2 rounded-lg shadow-none hover:shadow-xl bg-dark">
          <h1 className="p-1 text-2xl text-light">Experience</h1>
          <br />
          <div className="flex flex-wrap">
            {experience.map((job) => {
              let details = job.details;
              return (
                <div className="p-1 mx-auto w-full shrink-0">
                  <div className="shadow-none hover:shadow-xl h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden text-light2">
                    <div className="p-6">
                      <div className="flex">
                        <div className="mr-2 flex">
                          <img
                            src={job.imgLink}
                            alt="One Iota Performance Logo"
                            className="my-auto"
                            width={100}
                          />
                        </div>
                        <div>
                          <h3 className="tracking-widest text-xs title-font font-medium text-light2 mb-1">
                            {job.dates}
                          </h3>
                          <h2 className="title-font text-lg font-medium text-light2  xs-plus:block  xl:block">
                            {job.position}
                          </h2>
                          <h1 className="title-font text-base font-medium text-lighter  xs-plus:block  xl:block">
                            {job.company}
                          </h1>
                        </div>
                      </div>

                      <div className="leading-relaxed mb-3">
                        {details.map((line) => {
                          return <p className="py-1">{line}</p>;
                        })}
                      </div>
                      <h1 className="title-font text-sm font-medium text-light2 mb-1">
                        Most Relevant Skills
                      </h1>
                      <div className="w-full flex flex-wrap">
                        {job.skills.map((skill) => {
                          return (
                            <span className="inline-flex items-center justify-center px-3 py-1 mr-2 mb-2 text-sm font-medium leading-5  text-[#F8F4E3] bg-secondary/20  rounded-full">
                              {skill}
                            </span>
                          );
                        })}
                      </div>
                      <div className="flex items-center flex-wrap">
                        {job.company_site ? (
                          <a
                            href={job.company_site}
                            rel="noreferrer"
                            target="_blank"
                            className="text-accent inline-flex items-center md:mb-2 lg:mb-0">
                            Company Site
                            <svg
                              className="w-4 h-4 ml-2"
                              viewBox="0 0 24 24"
                              stroke="#00fdd2"
                              stroke-width="2"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round">
                              <path d="M5 12h14"></path>
                              <path d="M12 5l7 7-7 7"></path>
                            </svg>
                          </a>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <br />
        <hr className="mx-2 text-accent border-gray-500 border-rounded border" />
        <br />

        <div
          id="education"
          className="mx-2 p-2 rounded-lg shadow-none hover:shadow-xl bg-dark">
          <h1 className="p-1 text-2xl text-light">Education</h1>
          <br />
          <div className="flex flex-wrap">
            {education.map((program) => {
              return (
                <div className="p-1 mx-auto w-full shrink-0">
                  <div className="shadow-none hover:shadow-xl h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden text-light2">
                    <div className="p-6">
                      <div className="flex">
                        <div className="mr-2">
                          <img
                            src="/img/sfu-logo.png"
                            alt="SFU Logo"
                            width={100}
                          />
                        </div>
                        <div>
                          <h2 className="tracking-widest text-xs title-font font-medium text-light2 mb-1">
                            {program.dates}
                          </h2>
                          <h1 className="title-font text-lg font-medium text-lighter hidden xs-plus:block md:hidden xl:block">
                            {program.school}
                          </h1>
                        </div>
                      </div>
                      <h1 className="title-font text-lg font-medium text-lighter block xs-plus:hidden md:block xl:hidden">
                        {program.school}
                      </h1>
                      <h1 className="title-font text-md font-normal text-light2 mb-3">
                        {program.degree}
                      </h1>

                      <h1 className="title-font text-sm font-medium text-light2 mb-1">
                        Most Relevant Courses
                      </h1>
                      <div className="w-full flex flex-wrap">
                        {program.courses.map((course) => {
                          return (
                            <span className="inline-flex items-center justify-center px-3 py-1 mr-2 mb-2 text-xs font-medium leading-5  text-[#F8F4E3] bg-secondary/20  rounded-full">
                              {course}
                            </span>
                          );
                        })}
                      </div>
                      <div className="flex items-center flex-wrap">
                        {program.school_site ? (
                          <a
                            href={program.school_site}
                            className="text-accent inline-flex items-center md:mb-2 lg:mb-0">
                            School Site
                            <svg
                              className="w-4 h-4 ml-2"
                              viewBox="0 0 24 24"
                              stroke="#00fdd2"
                              stroke-width="2"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round">
                              <path d="M5 12h14"></path>
                              <path d="M12 5l7 7-7 7"></path>
                            </svg>
                          </a>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <br />
        <hr className="mx-2 text-accent border-gray-500 border-rounded border" />
        <br />

        <div
          id="projects"
          className="mx-2 p-2 rounded-lg shadow-none hover:shadow-xl bg-dark">
          <h1 className="p-1 text-2xl text-light">Projects</h1>
          <br />

          <a
            href="/projects"
            className="m-1 rounded-lg shadow-none hover:shadow-xl w-fit px-4 py-2 bg-secondary text-dark hover:bg-dark hover:text-secondary align-middle hover:cursor-pointer flex">
            See all Projects
          </a>

          <br />
          <div className="flex flex-wrap">
            {projects.map((project) => {
              let projectDetails = project.details;
              return (
                <div className="p-1 md:w-1/2 mx-auto lg:w-1/3 w-full shrink-0">
                  <div className="shadow-none hover:shadow-xl h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden text-light2">
                    <img
                      className="lg:h-72 md:h-64 sm:h-72 h-64 w-full object-cover hover:object-contain object-top"
                      src={project.imgLink}
                      alt={"Project " + project.name}
                    />
                    <div className="p-6">
                      <h2 className="tracking-widest text-xs title-font font-medium text-light2 mb-1">
                        {project.dates}
                      </h2>
                      <h1 className="title-font text-lg font-medium text-lighter mb-3">
                        {project.name}
                      </h1>
                      <div className="leading-relaxed mb-3">
                        {projectDetails.map((line) => {
                          return <p className="py-1">{line}</p>;
                        })}
                      </div>
                      <div className="flex items-center flex-wrap">
                        {project.projectLink ? (
                          <a
                            href={project.projectLink}
                            rel="noreferrer"
                            target="_blank"
                            className="text-accent inline-flex items-center md:mb-2 lg:mb-0">
                            See Project
                            <svg
                              className="w-4 h-4 ml-2"
                              viewBox="0 0 24 24"
                              stroke="#00fdd2"
                              stroke-width="2"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round">
                              <path d="M5 12h14"></path>
                              <path d="M12 5l7 7-7 7"></path>
                            </svg>
                          </a>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="flex items-center flex-wrap">
                        {project.gitLink ? (
                          <a
                            href={project.gitLink}
                            rel="noreferrer"
                            target="_blank"
                            className="text-accent inline-flex items-center md:mb-2 lg:mb-0">
                            See Code
                            <svg
                              className="w-4 h-4 ml-2"
                              viewBox="0 0 24 24"
                              stroke="#00fdd2"
                              stroke-width="2"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round">
                              <path d="M5 12h14"></path>
                              <path d="M12 5l7 7-7 7"></path>
                            </svg>
                          </a>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <br />
        <hr className="mx-2 text-accent border-gray-500 border-rounded border" />
        <br />

        <div className="w-full flex">
          <a
            href="/resume.pdf"
            target="_blank"
            className="rounded-lg shadow-none hover:shadow-xl px-4 py-2 hover:bg-dark hover:text-accent text-dark bg-accent mx-auto hover:cursor-pointer">
            <i className="fa-solid fa-file-pdf"></i> Prefer a PDF version? Click
            here!
          </a>
        </div>

        <br />
        <br />
      </div>
    </div>
  );
}

export default Resume;
