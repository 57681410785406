import React from 'react'
import { useParams } from 'react-router'

function NotFound() {
let {url} = useParams();

  return (
    <div className='p-2'>
        <p className='text-6xl text-center text-red-500'>ERROR 404: "/{url}" NOT FOUND</p>
    </div>
  )
}

export default NotFound