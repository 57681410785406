import { Project } from "../types/DataTypes";

export const listOfProjects: Project[] = [
  {
    id: 0,
    name: "Python Image Processor",
    details: [
      "- Created an image processor by using Python to calculate changes to the RGB values of each pixel to help manipulate images to make changes to different aspects of the pixels",
      "- Used and updated parts of a provided test driver by using libraries like NumPy and Pygame to test my group's code to make sure the images are being manipulated properly",
      "- Calculated changes needed by getting pixel values, and applying simple operations such as addition, subtraction, division, and multiplication to get new values for the pixels"
    ],
    dates: "November 2020 - December 2020",
    projectLink: "",
    gitLink: "",
    imgLink: "/img/pyip.png"
  },
  {
    id: 1,
    name: "Memory Game",
    details:  [
      '- Updated a provided test driver by calling on the necessary functions in a "while loop" to make the part of the game that the users interact with',
      '- Designed a virtual deck of cards and a card counter for each player by using a doubly linked list and helper functions to initialize a deck of cards and manipulate them',
      '- Tested the game by pasting the text from a provided text document to make sure the correct and outcome of the game is achieved'
    ],
    dates: "March 2021 - April 2021",
    projectLink: "",
    gitLink: "",
    imgLink: "/img/memgam.png"
  },
  {
    id: 2,
    name: "Fortress Defense Game",
    details: [
      '- Created an Object-Oriented Design using UML Class Diagrams and CRC Card to make a single-player "Battleship" type game',
      '- Built a command line application using Java to implement the Object-Oriented Design',
      '- Debugged the program by testing and fixing code while working on it to ensure it does not randomly crash without displaying a user-readable error.',
    ],
    dates: "February 2022 - March 2022",
    projectLink: "",
    gitLink: "",
    imgLink: "/img/fortdef.png"
  },
  {
    id: 3,
    name: "Web Application for a Religious Institution",
    details: [
      "- Created and incrementally updated views using HTML, Bootstrap, and EJS files to give users a clean, easily usable interface, on all types of displays and devices",
      "- Worked with a team using agile software engineering principles to create a functional web app with various usertypes, each with varying permissions",
      "- Created a backend using Node.js, Express.js, and Node modules such as nodemailer, and PostgreSQL databases. Some functionality includes things such as verification processes, sending automatic emails, and integrating various external APIs to give the site even more functionality",
    ],
    dates: "February 2022 - April 2022",
    projectLink: "https://cmpt276-project-group3.herokuapp.com/",
    gitLink: "https://github.com/cmpt-276-group-3/project",
    imgLink: "/img/webdev.png"
  },
  {
    id: 4,
    name: "Volunteer Website Development",
    details:  [
      "Designed a website for a school using Express as a back-end, React as a front-end, and a PostgreSQL database",
      "Created UI for the library functionality, allowing admins to add and remove books from the database and allowing teachers to 'check-out' and 'check-in' books for their students",
      "Used Node modules such as nodemailer and randomstring as well as Google OAuth2, and Postgres to create an email verification system to ensure that users are registering with their own valid email address",
    ],
    dates: "April 2022 - Present",

    projectLink:"https://madrasah-reactsite.herokuapp.com/",
    gitLink:"https://github.com/shabbiryusufali/madrasah-reactsite",
    imgLink: "/img/madweb.png"
  },
  {
    id: 5,
    name: "Parking Lot Management App",
    details: [
      '- Created a Parking Lot Management Tool to keep track of cars in parking lots and how many spots are available',
      '- Setup a REST API in Node.js to manage and store User and Parking Lot data in a PostgreSQL Database',
      '- Created a clean, minimalistic front-end using React and TailwindCSS to display the necessary data to the user '
    ],
    dates: "October 2022",

    projectLink:"https://fall-hack-2022.herokuapp.com/",
    gitLink:"https://github.com/fall-hack-2022/fall-hack",
    imgLink: "/img/fall-hack-2022.png"
  },
  {
    id: 6,
    name: "Pip-Boy 3000 Mk IV",
    details: [
      '- Developed a true-t-source prototype model of the Pip-Boy 3000 Mk IV from the Fallout series using a 3D printedmodel, an Arduino setup, and Android Studio with Kotlin',
      '- Wrote Kotlin code and created XML layouts for Android to develop the software for the model',
      '- Using a 3D printer, developed a 3D model of the Pip-Boy, and modified it to fit the Arduino and other components'
    ],
    dates: "May 2023 - July 2023",
    projectLink:"https://drive.google.com/file/d/1atzK_yNAKQQ8PUuTeW8KfsdhSt1w-qoC/view?usp=sharing",
    gitLink:"https://github.com/cmpt469-su23-group3/PipBoy_Android",
    imgLink: "img/pipboy.jpg"

  },
  {
    id: 7,
    name: "Cinébon",
    details: [
      '- Developed an app using Kotlin and Compose to allow users to find movies and TV shows to watch, and to keeptrack of what they want to watch and what they have watched',
      '- Used Firebase Auth and Firestore to store all our data and authenticate users',
      '- Used The Movie Database (TMDB) API to get information about movies and TV shows, and to get images for our app\'s main feature'
    ],
    dates: "Nov 2023 - Dec 2023",
    projectLink:"https://cinebon.online/",
    gitLink:"https://github.com/CMPT-362-Group3/Cinebon",
    imgLink: "img/cinebon.png"

  }
];
