import React from 'react'
import { Disclosure } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { useParams } from 'react-router'

const navigation = [
  // { name: 'Home', href: '/', current: false },
  { name: 'About Me', href: 'about', current: false },
  { name: 'Resume', href: 'resume', current: false },
  { name: 'Projects', href: 'projects', current: false },
  { name: 'Photography', href: 'photography', current: false },
]

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export default function Navbar() {

  let { url } = useParams();

  navigation.forEach(link => {
    if (url) {
      if (url === link.href) {
        link.current = true;
      }
    } else if (link.href === '/') {
      link.current = true

    }
  })

  return (
    <Disclosure as="nav" className="bg-dark static max-w-screen z-50">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-light2 bg-dark hover:bg-primary hover:text-black focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-stretch ">
                <div className="flex-shrink-0 flex items-center">
                  <a href='/'><img
                    className="block lg:hidden h-9 w-auto"
                    src="img/logo-full.png"
                    alt="Workflow"
                  />
                  <img
                    className="hidden lg:block h-9 w-auto"
                    src="img/logo-full.png"
                    alt="Workflow"
                  />
                  </a>
                </div>
                <div className="hidden lg:block align-middle lg:ml-6">
                  <div className="flex space-x-4 align-middle">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          item.current ? 'bg-dark  text-light' : 'text-light2  bg-dark hover:bg-primary hover:text-black',
                          'px-6 py-2 rounded-md text-sm font-medium align-middle'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </a>
                    ))}
                    <div className="bg-dark flex text-md  align-middle rounded-full focus:outline-none focus:ring-3 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">

                      <img
                        className="h-9 w-9  align-middle rounded-full"
                        src="img/about1.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>

              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 lg:static  lg:inset-auto lg:ml-6 lg:pr-0">

              </div>
            </div>
          </div>

          <Disclosure.Panel className="lg:hidden">
            <div className="px-6 pt-2 pb-3 space-y-1">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current ? 'bg-dark text-light' : 'text-light2 hover:bg-primary hover:text-black',
                    'block px-8 py-2 rounded-md text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
