import { EducationData } from "../types/DataTypes";

export const listOfEducation: EducationData[] = [
	{
	  id: 0,
	  school: "Simon Fraser University",
	  degree: "Bachelor of Science, Major in Computing Science",
	  dates: "September 2020 - Present",
	  school_site: "https://www.sfu.ca/",
	  courses: [
		"Data Structures and Algorithms",
		"Discrete Mathematics",
		"Web Development",
		"UI/UX/HCI Design",
		"Networking",
		"Mobile Application Development and Design",
		"Computer Systems",
	  ],
	},
  ];
  