import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './components/App';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Photography from './components/Photography';
import About from './components/About';
import Projects from './components/Projects';
import NotFound from './components/NotFound';
import Resume from './components/Resume';
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react"

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <BrowserRouter>
    <Analytics />
    <SpeedInsights />
    <div className='h-screen max-w-screen flex flex-col bg-dark'>
      <Routes>

        <Route path="/" element={<Navbar />} />
        <Route path="/:url" element={<Navbar />} />

      </Routes>
      <div className='flex-grow max-w-screen bg-dark'>
        <Routes>
          <Route path='/' element={<App />} />
          <Route path='/photography' element={<Photography />} />
          <Route path='/about' element={<About />} />
          <Route path='/resume' element={<Resume />} />
          <Route path='/projects' element={<Projects />} />
          <Route path='/:url' element={<NotFound />} />
        </Routes>
      </div>
      <Footer />
    </div>
  </BrowserRouter>
);

